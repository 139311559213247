import * as dateFns from 'date-fns';
import PropTypes from 'prop-types';
import React from 'react';

import { formatDateTime, formatMoney } from '../../lib/fmt';
import { cn } from '../../lib/utils';
import Button from '../Button';
import List from '../List';
import MClaimEdit from '../modals/MClaimEdit';
import MOrderClaimCreate from '../modals/MOrderClaimCreate';

export default class PClaims extends React.Component {
  static propTypes = {
    app: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      items: [],
      search: '',
      filter: 'not_applied',
      page: 1,
    };
  }

  render() {
    return (
      <div className="PClaims">
        {this.renderTop()}
        {this.renderList()}
      </div>
    );
  }

  async componentDidMount() {
    const { app } = this.props;
    app.setupPage(null, 'Заявки');
    const items = app.loadFromCache('claims');
    if (items) {
      this.setState({ items });
    }
    this.setupWebSocket();
    await this.refreshData();
  }

  componentWillUnmount() {
    const { app } = this.props;
    const ws = app.getWebSocket();
    ws.registerMessageHandler(undefined);
    ws.registerReconnectHandler(undefined);
  }

  // event handlers

  onSearchChange(e) {
    this.setState({ search: e.target.value, page: 1 });
  }

  onFilterChange(e) {
    this.setState({ filter: e.target.value, page: 1 });
  }

  async onAdd() {
    const { app } = this.props;
    const claim = await app.showModal(MOrderClaimCreate, { type: 'claim' });
    if (!claim) {
      return;
    }
    await app.showModal(MClaimEdit, { claim, isEditMode: true });
    await this.refreshData();
  }

  onItemFilter(claim) {
    const { filter } = this.state;
    switch (filter) {
      case 'applied':
        return Boolean(claim.ord_applied_at) && !claim.archived_at;
      case 'archived':
        return Boolean(claim.archived_at);
      default:
        return !claim.ord_applied_at && !claim.archived_at;
    }
  }

  onItemGetClassName(claim, prevClaim) {
    const { filter } = this.state;
    const hasOrderClass = filter === 'not_applied' && claim.ord_id ? 'table-success' : '';
    if (!prevClaim) {
      return hasOrderClass;
    }
    let field = '';
    switch (filter) {
      case 'applied':
        field = 'ord_applied_at';
        break;
      case 'archived':
        field = 'archived_at';
        break;
      default:
        field = 'created_at';
        break;
    }
    const d1 = dateFns.format(prevClaim[field], 'YYYY-MM-DD');
    const d2 = dateFns.format(claim[field], 'YYYY-MM-DD');
    if (d1 === d2) {
      return hasOrderClass;
    }
    return cn(hasOrderClass, 'List_rowWithTopSeparator');
  }

  async onItemSelect(claim) {
    const { app } = this.props;
    await app.showModal(MClaimEdit, { claim });
    await this.refreshData();
  }

  onPageChange(page) {
    const { app } = this.props;
    this.setState({ page });
    app.scrollToTop();
  }

  // render helpers

  renderTop() {
    const { search, filter } = this.state;
    return (
      <div className="d-flex mb-3">
        <Button type="primary" text="Добавить" onClick={() => this.onAdd()} />
        <input
          className="form-control ml-3"
          type="text"
          value={search}
          placeholder="Поиск"
          onChange={(e) => this.onSearchChange(e)}
        />
        <select className="form-control ml-3" value={filter} onChange={(e) => this.onFilterChange(e)}>
          <option value="not_applied">Непроведённые</option>
          <option value="applied">Проведённые</option>
          <option value="archived">Архив</option>
        </select>
      </div>
    );
  }

  renderList() {
    const { items, search, page } = this.state;
    return (
      <List
        columns={this.getColumns()}
        items={items}
        search={search}
        pageSize={100}
        pageNumber={page}
        onItemFilter={(item) => this.onItemFilter(item)}
        onItemGetClassName={(item, prevItem) => this.onItemGetClassName(item, prevItem)}
        onItemSelect={(item) => this.onItemSelect(item)}
        onPageChange={(page) => this.onPageChange(page)}
      />
    );
  }

  // other helpers

  setupWebSocket() {
    const { app } = this.props;
    const ws = app.getWebSocket();
    ws.registerMessageHandler(async (msg) => {
      if (msg.event === 'claim_update') {
        this.refreshData();
      }
    });
    ws.registerReconnectHandler(async () => {
      this.refreshData();
    });
  }

  getColumns() {
    const { filter } = this.state;
    return [
      {
        name: '#',
        value: (item) => item.id,
        headClassName: 'w-80px',
      },
      {
        name: 'Статус',
        value: (item) => `${item.product_stock_qty} / ${item.product_qty}`,
        headClassName: cn('w-120px', ['applied', 'archived'].includes(filter) && 'd-none'),
        cellClassName: (item) =>
          cn(
            'font-weight-bold',
            item.product_qty > item.product_stock_qty ? 'text-danger' : 'text-success',
            ['applied', 'archived'].includes(filter) && 'd-none',
          ),
      },
      {
        name: (() => {
          switch (filter) {
            case 'applied':
              return 'Проведена';
            case 'archived':
              return 'Архивирована';
            default:
              return 'Создана';
          }
        })(),
        value: (item) =>
          formatDateTime(
            (() => {
              switch (filter) {
                case 'applied':
                  return item.ord_applied_at;
                case 'archived':
                  return item.archived_at;
                default:
                  return item.created_at;
              }
            })(),
          ),
        headClassName: 'w-180px',
        cellClassName: (item) => (item.has_record ? 'text-primary' : ''),
      },
      {
        name: 'Клиент',
        value: (item) => item.contractor_name,
      },
      {
        name: 'Гос. номер',
        value: (item) => item.vehicle_reg,
        headClassName: 'w-140px',
      },
      {
        name: 'Автомобиль',
        value: (item) => `${item.vehicle_brand} ${item.vehicle_model}`,
        headClassName: 'w-220px',
      },
      {
        name: 'Сумма',
        value: (item) => formatMoney(item.product_amount + item.activity_amount, true, true),
        headClassName: 'w-140px',
        cellClassName: (item) => (item.prepayment_amount ? 'text-danger' : ''),
      },
    ];
  }

  async refreshData() {
    const { app } = this.props;
    try {
      const { items } = await app.getApi().get('/claims');
      app.saveToCache('claims', items);
      this.setState({ items });
    } catch (err) {
      app.onError(err);
    }
  }
}
