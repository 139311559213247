import * as dateFns from 'date-fns';
import PropTypes from 'prop-types';
import React from 'react';
import { formatDateTime, formatMoney } from '../../lib/fmt';
import Button from '../Button';
import List from '../List';
import MOrderClaimCreate from '../modals/MOrderClaimCreate';
import MOrderEdit from '../modals/MOrderEdit';

export default class POrders extends React.Component {
  static propTypes = {
    app: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      items: [],
      search: '',
      filter: 'not_applied',
      sort: 'default',
      page: 1,
    };
  }

  render() {
    return (
      <div className="POrders">
        {this.renderTop()}
        {this.renderList()}
      </div>
    );
  }

  async componentDidMount() {
    const { app } = this.props;
    app.setupPage(null, 'Заказы');
    const items = app.loadFromCache('orders');
    if (items) {
      this.setState({ items });
    }
    this.setupWebSocket();
    await this.refreshData();
  }

  componentWillUnmount() {
    const { app } = this.props;
    const ws = app.getWebSocket();
    ws.registerMessageHandler(undefined);
    ws.registerReconnectHandler(undefined);
  }

  // event handlers

  onSearchChange(e) {
    this.setState({ search: e.target.value, page: 1 });
  }

  onFilterChange(e) {
    this.setState({ filter: e.target.value, page: 1 });
  }

  onSortChange(e) {
    this.setState({ sort: e.target.value, page: 1 });
  }

  async onAdd() {
    const { app } = this.props;
    const order = await app.showModal(MOrderClaimCreate, { type: 'order' });
    if (!order) {
      return;
    }
    await app.showModal(MOrderEdit, { order, isEditMode: true });
    await this.refreshData();
  }

  onItemFilter(order) {
    const { filter } = this.state;
    return Boolean(order.applied_at) === (filter === 'applied');
  }

  onItemGetClassName(order, prevOrder) {
    const { sort, filter } = this.state;
    if (sort !== 'default') {
      return undefined;
    }
    if (!prevOrder) {
      return undefined;
    }
    const field = filter === 'applied' ? 'applied_at' : 'created_at';
    const d1 = dateFns.format(prevOrder[field], 'YYYY-MM-DD');
    const d2 = dateFns.format(order[field], 'YYYY-MM-DD');
    if (d1 === d2) {
      return undefined;
    }
    return 'List_rowWithTopSeparator';
  }

  async onItemSelect(order) {
    const { app } = this.props;
    await app.showModal(MOrderEdit, { order });
    await this.refreshData();
  }

  onPageChange(page) {
    const { app } = this.props;
    this.setState({ page });
    app.scrollToTop();
  }

  // render helpers

  renderTop() {
    const { search, sort, filter } = this.state;
    return (
      <div className="d-flex mb-3">
        <Button type="primary" text="Добавить" onClick={() => this.onAdd()} />
        <input
          className="form-control ml-3"
          type="text"
          value={search}
          placeholder="Поиск"
          onChange={(e) => this.onSearchChange(e)}
        />
        <select className="form-control ml-3" value={sort} onChange={(e) => this.onSortChange(e)}>
          <option value="default">{filter === 'applied' ? `По дате проведения` : `По дате создания`}</option>
          <option value="by_updated_at">По дате изменения</option>
        </select>
        <select className="form-control ml-3" value={filter} onChange={(e) => this.onFilterChange(e)}>
          <option value="not_applied">Непроведённые</option>
          <option value="applied">Проведённые</option>
        </select>
      </div>
    );
  }

  renderList() {
    const { search, page } = this.state;
    return (
      <List
        columns={this.getColumns()}
        items={this.getItems()}
        search={search}
        pageSize={100}
        pageNumber={page}
        onItemFilter={(item) => this.onItemFilter(item)}
        onItemGetClassName={(item, prevItem) => this.onItemGetClassName(item, prevItem)}
        onItemSelect={(item) => this.onItemSelect(item)}
        onPageChange={(page) => this.onPageChange(page)}
      />
    );
  }

  // other helpers

  setupWebSocket() {
    const { app } = this.props;
    const ws = app.getWebSocket();
    ws.registerMessageHandler(async (msg) => {
      if (msg.event === 'order_update') {
        this.refreshData();
      }
    });
    ws.registerReconnectHandler(async () => {
      this.refreshData();
    });
  }

  getColumns() {
    const { filter } = this.state;
    return [
      {
        name: '#',
        value: (item) => item.id,
        headClassName: 'w-80px',
      },
      {
        name: filter === 'applied' ? 'Проведён' : 'Создан',
        value: (item) => formatDateTime(filter === 'applied' ? item.applied_at : item.created_at),
        headClassName: 'w-180px',
      },
      {
        name: 'Клиент',
        value: (item) => item.contractor_name,
      },
      {
        name: 'Гос. номер',
        value: (item) => item.vehicle_reg,
        headClassName: 'w-140px',
      },
      {
        name: 'Автомобиль',
        value: (item) => `${item.vehicle_brand} ${item.vehicle_model}`,
        headClassName: 'w-220px',
      },
      {
        name: 'Сумма',
        value: (item) => formatMoney(item.product_amount + item.activity_amount, true, true),
        headClassName: 'w-140px',
        cellClassName: (item) => (item.claim_prepayment_amount ? 'text-danger' : ''),
      },
      {
        name: 'Пробег',
        value: (item) => <div className="text-center">{item.mileage && <i className="fas fa-check" />}</div>,
        headClassName: 'w-80px text-center',
      },
    ];
  }

  getItems() {
    const { items, sort } = this.state;
    if (sort === 'default') {
      return items;
    }
    const sortedItems = [...items];
    sortedItems.sort((a, b) => (b.updated_at === a.updated_at ? 0 : b.updated_at > a.updated_at ? 1 : -1));
    return sortedItems;
  }

  async refreshData() {
    const { app } = this.props;
    try {
      const { items } = await app.getApi().get('/orders');
      app.saveToCache('orders', items);
      this.setState({ items });
    } catch (err) {
      app.onError(err);
    }
  }
}
